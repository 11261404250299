import { createContext, useState } from "react"

const AppContext: any = createContext(null)

export const AppProvider = ({ children }: any) => {

    const [contactForm, setContactForm] = useState(false)

    // const toggleForm = (state: any) => {
    //     console.log(state)
    //     setContactForm(state)
    // }

    return (
        <AppContext.Provider value={{ contactForm, setContactForm }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContext