import styles from './Button.module.scss'

const Button = ({ text, classname = 'button-wrapper' }: any) => {
  return (
    <div className={styles[classname]}>
      <span>{text}</span>
    </div>
  )
}

export default Button
