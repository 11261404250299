import styles from './Modal.module.scss'

const Modal = ({ children }: any) => {
    return (
        <div className={styles['modal-container']}>
            {children}
        </div>
    )
}

export default Modal