import React, { PropsWithChildren } from 'react';
import Head from 'next/head';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const Layout = ({ children }: PropsWithChildren) => {
  return (
    <div className="layout">
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon shortcut" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta property="og:site_name" content="Socommerz" />
        <meta name="facebook-domain-verification" content="j6wo9fvs7cfuony0bn65gqoml6mp1n" />
        <meta name="google-site-verification" content="d2kz-1obnrxkyNrsUfnoqQwKwVB0pVbQtmbJKHgSdo8" />
      </Head>
      <header>
        <Navbar />
      </header>
      <main className="main-container">
        {children}
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  )
}

export default Layout