import React, { useContext, useState } from 'react'
import styles from './Navbar.module.scss'
// import Button from '../../Common/Button/Button';
import Button from '../Button/Button'
import Link from 'next/link'
import AppContext from '../../../context/AppContext'
import Modal from '../Modal/Modal'
import ContactForm from '../Contact/Contact'
import Image from 'next/image'
import { useRouter } from 'next/router'
const Navbar = () => {
  const router = useRouter()
  const isHomePage = router.pathname === '/'
  console.log({ isHomePage })

  const [showMenu, setShowMenu] = useState(false)
  const { contactForm, setContactForm }: any = useContext(AppContext)
  const solutionList = [
    {
      id: 1,
      img: '/b2b-white.png',
      title: 'B2B Stack',
      description: '',
      path: '/b2b-stack',
    },
    {
      id: 2,
      img: '/b2c-new.png',
      title: 'B2C & D2C Stack',
      description: '',
      path: '/b2c-d2c-stack',
    },
    {
      id: 3,
      img: '/social-netwrok.png',
      title: 'Social Commerce Stack',
      description: '',
      path: '/social-commerce',
    },
    {
      id: 4,
      img: '/generative-ai.png',
      title: 'Generative AI Wrappers',
      description: '',
      path: '/generative-ai',
    },
    {
      id: 5,
      img: '/new-solution.png',
      title: 'Headless Solutions',
      description: '',
      path: '/product-stack',
    },
  ]

  const priceEngineList = [
    {
      id: 1,
      img: '/etail-new-icon.png',
      title: 'eTail Data Lake',
      description: '',
      path: '/eTail-data-lake',
    },
    {
      id: 2,
      img: '/new-solution.png',
      title: 'Headless Price Engine',
      description: '',
      path: '/headless-price-engine-ai',
    },
    {
      id: 3,
      img: '/trend-new.png',
      title: 'Trend Analyzer',
      description: '',
      path: '/trend-analyzer',
    },
  ]
  return (
    <nav className={styles['navbar-wrapper']}>
      <div className={styles.navbar}>
        {/* <Link href={'/'}> */}
        <div className={styles.logoContainer}>
          <Link href={'/'} style={{ display: 'flex', alignItems: 'center' }}>
            <img src='logo.svg' alt='logo' style={{ width: 25, height: 25 }} />
            <p className={styles.logoText}>socommerz</p>
          </Link>
        </div>
        {/* </Link> */}

        <div>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <Link href={'about-us'}>About</Link>
            </li>

            <li className={styles.listItem}>
              Solutions<span>&#8964;</span>
              <ul className={styles['dropdown']}>
                <div className={styles['dropdown-container']}>
                  {solutionList?.map((item, index) => (
                    <li
                      className={`${styles['dropdown-item']}`}
                      key={index + item?.id}
                    >
                      <Link
                        href={item.path}
                        className={styles['dropdown-item-detail']}
                      >
                        <div className={styles['dropdown-item-detail-img']}>
                          <Image
                            height={18}
                            width={24}
                            alt={`solution-icon`}
                            src={item?.img}
                            className={styles['img']}
                          />
                        </div>
                        <div className={styles['dropdown-item-detail-content']}>
                          <p className={styles['dropdown-item-detail-title']}>
                            {item?.title}
                          </p>
                          <p className={styles['dropdown-item-detail-des']}>
                            {item?.description}
                          </p>
                        </div>
                      </Link>
                    </li>
                  ))}
                </div>
                {/* <li className={styles["dropdown-item"]}>
                  <Link href="/b2c-d2c-stack">B2C & D2C Stack</Link>
                </li>
                <li className={styles["dropdown-item"]}>
                  <Link href="/social-commerce">Social Commerce Stack</Link>
                </li>
                <li className={styles["dropdown-item"]}>
                  <Link href="/generative-ai">Generative AI Wrappers</Link>
                </li>
                <li className={styles["dropdown-item"]}>
                  <Link href="/product-stack">Headless Solutions</Link>
                </li> */}
              </ul>
            </li>
            <li className={styles.listItem}>
              Price Engine<span>&#8964;</span>
              {/* <ul className={styles["dropdown"]}>
                <li className={styles["dropdown-item"]}>
                  <Link href="/eTail-data-lake">eTail Data Lake</Link>
                </li>
                <li className={styles["dropdown-item"]}>
                  <Link href="/headless-price-engine-ai">
                    Headless Price Engine
                  </Link>
                </li>

                <li className={styles["dropdown-item"]}>
                  <Link href="/trend-analyzer">Trend Analyzer</Link>
                </li>
              </ul> */}
              <ul className={styles['dropdown']}>
                <div className={styles['dropdown-container']}>
                  {priceEngineList?.map((item, index) => (
                    <li
                      className={styles['dropdown-item']}
                      key={index + item?.id}
                    >
                      <Link
                        href={item.path}
                        className={styles['dropdown-item-detail']}
                      >
                        <div className={styles['dropdown-item-detail-img']}>
                          <Image
                            height={18}
                            width={24}
                            alt={item?.title}
                            src={item?.img}
                          />
                        </div>
                        <div className={styles['dropdown-item-detail-content']}>
                          <p className={styles['dropdown-item-detail-title']}>
                            {item?.title}
                          </p>
                          <p className={styles['dropdown-item-detail-des']}>
                            {item?.description}
                          </p>
                        </div>
                      </Link>
                    </li>
                  ))}
                </div>
              </ul>
            </li>
            <li className={styles.listItem}>
              <Link href={'our-associations'}>Our Associations</Link>
            </li>
            {/* <li className={styles.listItem}>
              <Link href={'/generative-ai'}>Generative AI</Link>
              <div className={styles["mega-drop"]}>
                <div className={styles["mega-grid"]}>
                 
                   <div className={styles["mega-child-first"]}>
                      <h5>BY STAGE</h5>
                      <ul>
                        <li><a href="#">Start-ups </a></li>
                        <li> <a href="#">Enterprises  </a></li>
                      </ul>
                  
                      <a href="#">Enterprises  </a>
                   </div>
                   <div className={styles["mega-child-second"]}>
                      <h5>BY USE CASE</h5>
                      <ul>
                        <li><a href="#">Start-ups </a></li>
                        <li> <a href="#">Enterprises  </a></li>
                      </ul>
                   </div>
                   <div className={styles["mega-child-third"]}>
                      <h5>INTEGRATIONS & CUSTOM SOLUTIONS</h5>
                      <ul>
                        <li><a href="#">Start-ups </a></li>
                        <li> <a href="#">Enterprises  </a></li>
                      </ul>
                   </div>

                </div>
              </div>
            </li> */}

            {/* <li className={styles.listItem}>
              <Link href={'technology'}>Technology</Link>
            </li> */}
          </ul>
        </div>
        <div className={styles.buttonDiv}>
          <Link
            target='blank'
            href='https://docs.google.com/forms/d/e/1FAIpQLScUKXrtoPaq2W2ag_cyszvEQkfs8ou4RV-BXd93rJl9E48y0g/viewform?usp=sf_link'
          >
            <Button text='We Are Hiring' classname='button-wrapper' />
          </Link>
        </div>
        <div onClick={() => setShowMenu(showMenu === true ? false : true)}>
          {/* <p className={styles.more}>=</p> */}
          {/* <p style={{color:'white', position:'absolute', top:20, right:30}}>=</p> */}
          <img src='nav.svg' alt='more' className={styles['more']} />
        </div>
      </div>

      <nav role='navigation' className={styles['mobile-navigation']}>
        <div
          id={`${styles['menuToggle']}`}
          onClick={() => setShowMenu(!showMenu)}
        >
          {/* <form> */}
          <label htmlFor='hamburger'>Menu</label>
          <input
            type='checkbox'
            checked={showMenu}
            onChange={(e) => {}}
            id='hamburger'
            name='hamburger'
            aria-labelledby='hamburger'
          />
          <span></span>
          <span></span>
          <span></span>
          {/* </form> */}
          <ul id={styles['menu']}>
            <Link href='/about-us'>
              <li>
                <button>About Us</button>
              </li>
            </Link>
            <Link href='/our-associations'>
              <li>
                <button>Our Associations</button>
              </li>
            </Link>
            <Link href='/product-stack'>
              <li>
                <button>Product Stack</button>
              </li>
            </Link>
            <Link href='/b2b-stack'>
              <li>
                <button>B2B Stack</button>
              </li>
            </Link>
            <Link href='/b2c-d2c-stack'>
              <li>
                <button>B2C & D2C Stack</button>
              </li>
            </Link>
            <Link href='/social-commerce'>
              <li>
                <button>Social Commerce Stack</button>
              </li>
            </Link>
            <Link href='/eTail-data-lake'>
              <li>
                <button>eTail Data Lake</button>
              </li>
            </Link>
            <Link href='/headless-price-engine-ai'>
              <li>
                <button> Headless Price Engine</button>
              </li>
            </Link>
            <Link href='/trend-analyzer'>
              <li>
                <button>Trend Analyzer</button>
              </li>
            </Link>

            <Link href='/generative-ai'>
              <li>
                <button>Generative AI</button>
              </li>
            </Link>
            <Link
              target='blank'
              href='https://docs.google.com/forms/d/e/1FAIpQLScUKXrtoPaq2W2ag_cyszvEQkfs8ou4RV-BXd93rJl9E48y0g/viewform?usp=sf_link'
            >
              <li>
                <button>We Are Hiring</button>
              </li>
            </Link>
          </ul>
        </div>

        <Link href={'/'} className={styles['mobile-logo']}>
          <img src='logo.svg' alt='logo' style={{ width: 20, height: 20 }} />
          <span>SOCOMMERZ</span>
        </Link>
      </nav>

      {contactForm && (
        <Modal>
          <ContactForm />
        </Modal>
      )}
    </nav>
  )
}

export default Navbar
