import React from 'react'
import styles from './SocialLinks.module.scss'
import Image from 'next/image'

const SocialLinks = () => {
    return (
        <div className={styles['social-media']}>

            <a href={'https://in.linkedin.com/company/socommerz-technologies-private-limited'}>
                <Image src='/icons/linkedin.png' alt='linkedin' width={30} height={30} />
            </a>

            <a href={'https://www.facebook.com/Socommerz'}>
                <Image src='/icons/facebook.png' alt='facebook' width={30} height={30} />
            </a>

        </div>
    )
}

export default SocialLinks