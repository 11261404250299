import React, { useContext, useState } from 'react'
import styles from './Contact.module.scss'
import emailjs from "@emailjs/browser";
import Confetti from '../Confetti/Confetti';
import AppContext from '../../../context/AppContext';

const ContactForm = () => {
    const [showFire, setShowFire] = useState(false)
    const [emailMobile, setEmailMobile] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [buttonText, setButtonText] = useState('Get In Touch')
    const [error, setError] = useState(false)
    const { setContactForm }: any = useContext(AppContext)

    const sendDetails = () => {
        let contactDetails = {
            name: name,
            emailMobile: emailMobile,
            description: description,
        }
        if (!emailMobile.length) {
            setError(true)
            return
        } else {
            setError(false)
        }
        setButtonText('Please Wait...')
        emailjs.send("service_cdxe3sf", "template_hqe3spd", contactDetails, 'gpZSb5VKXjS4k9rwL').then(
            (result) => {
                setDescription('')
                setName('')
                setEmailMobile('')
                setShowFire(true)

            },
            (error) => {
                // console.log(error.text);
            }
        );
    }

    return (
        <form className={styles['contact-form-container']}>
            <div className={styles['contact-form']}>
                <div className={styles['close-icon']} onClick={()=>setContactForm(false)}>
                <img src="close.svg" width="15" height="15" alt="" />
                </div>
                <span className={styles['contact-head']}>Let&apos;s Ideate</span>
                <div className={styles['input-box']}>
                    <label htmlFor="name">Name</label>
                    <input id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </div>

                <div className={styles['input-box']}>
                    <label htmlFor="emailMobile">Email / Mobile</label>
                    <input id="emailMobile" type="text" value={emailMobile} onChange={(e) => setEmailMobile(e.target.value)} />
                </div>

                <div className={styles['input-box']}>
                    <label htmlFor="description">Description</label>
                    <textarea id='description' value={description} onChange={(e) => setDescription(e.target.value)}>
                    </textarea>
                </div>

                {!showFire && <div className={styles['btn']}>
                    <button type="submit" onClick={(e) => {
                        e.preventDefault()
                        sendDetails()
                    }}>{buttonText}</button>
                </div>}

                {error && <div className={styles['message-sent']}>
                    <span>Please Enter Mobile or Email !!</span>
                </div>}

                {showFire && <div className={styles['message-sent']}>
                    <span>We will get to you soon. Let&apos;s create something big 😎</span>
                </div>}

                <Confetti showFire={showFire} />
            </div>
        </form>
    )
}

export default ContactForm